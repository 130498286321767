import React, { useState } from "react";
import Logoadmin from "../login/pngegg.png";
import "./login.css";
import logo from "../logos.png";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../privaterout/Authandicate';

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password,
    };

    try {
      const response = await axios.post('https://py.rccnorth.in/login', loginData);

      if (response.data.success) {
        setIsAuthenticated(true);
        toast.success("Login successful!", {
          onClose: () => navigate('/Totalreport')
        });
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Login failed. Please try again later.");
    }
  };

  return (
    <div>
      <div className="login-header p-2">
        <img src={logo} className="img-fluid" alt="logo" style={{ height: "2.8rem", width: "10rem" }} />
      </div>
      <div className="container">
        <div className="login-content">
          <div className="row">
            <div className="login-form">
              <div className="form-logo">
                <img src={Logoadmin} className="form-logo-img" alt="Rotary Logo" />
              </div>
              <h4 className="Admintext">Admin Login</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    className="input-form"
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <div className="password-container">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      name="password"
                      required
                      className="input-form"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="toggle-password"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? "Hide" : "Show"}
                    </button>
                  </div>
                </div>
                <button type="submit" className="login-button">
                  Log in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
